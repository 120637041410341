<template>
    <div class="card">
        <div class="card-body">
            <form @submit.prevent="save">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="news-croppa">
                                <div class="news-croppa-preview">
                                    <croppa v-model="myCroppa"
                                        ref="myCroppa"
                                        accept="image/*"
                                        :prevent-white-space="true"
                                        :show-remove-button="false"
                                        placeholder="Preview Image" 
                                        :width="400" 
                                        :height="250" 
                                        :quality="2"
                                        @file-choose="displayRefresh"
                                        />
                                </div>
                                <div class="news-croppa-actions">
                                    <button class="btn btn-warning" type="button" @click="selectPhoto">
                                        Select Photo
                                    </button>
                                    <button class="btn btn-danger" type="button" @click="removePhoto" v-if="isPhotoSelected">
                                        Remove Photo
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="">Title</label>
                            <input type="text" class="form-control" required v-model="post.title">
                        </div>
                        <div class="form-group">
                            <label for="">Category</label>
                            <select class="form-control" v-model="post.category">
                                <option v-for="(cat, i) in categories" :key="i">{{ cat }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="">Content</label>
                            <TextareaAutosize
                                required
                                class="form-control"
                                placeholder=""
                                ref="myTextarea"
                                v-model="post.content"
                                :min-height="120"
                                :max-height="350" />
                        </div>
                        <button class="btn btn-success" :disabled="isProcessing">
                            <span>Save News Item</span>
                            <loader v-if="isProcessing" />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
export default {
    data(){
        return {
            myCroppa: {},
            post: {
                title: "",
                category: "",
                content: "",
                photodata: "",
                gallery: [],
                createdAt: new Date(),
            },
            isProcessing: false,
            isPhotoSelected: false,
            categories: [],
        }
    },
    created(){
        this.getCategories()
    },
    methods: {

        async save(){
            
            this.isProcessing = true

            const photoDataBase64 = this.$refs.myCroppa.generateDataUrl()

            if (photoDataBase64 != "") {
                
                const filename = 'news/' + uuidv4() + '.jpg'

                const metadata = { contentType: 'image/jpeg', }

                // upload
                const task = this.$st.ref(filename).putString(photoDataBase64, 'data_url', metadata)

                // update progress
                task.on('state_changed', 
                    // progress
                    () => {},
                    // error
                    () => {},
                    // complete
                    async () => {
                        task.snapshot.ref.getDownloadURL().then(async downloadURL => {
                            this.post.photo = await downloadURL
                            this.publish(this.post)
                            this.isProcessing = false
                        })
                    }
                )
            } else {
                this.publish(this.post)
                this.isProcessing = false 
            }
        },

        async publish(post){
            // add the new to firstore
            const addNews = this.$fn.httpsCallable("addNews")
            const record = await addNews(post)

            // display success message
            this.$toast.success(record.data.message)

            // clear the form
            this.post = {
                title: "",
                category: "",
                content: "",
                photo: "",
                photodata: "",
                gallery: [],
                createdAt: new Date(),
            }

            // clear the croppa plugin
            this.removePhoto()
        },

        async getCategories(){
            const doc = await this.$db.collection("general").doc("news").get()
            const data = doc.data()
            this.categories = data.categories
        },

        selectPhoto(){
            this.$refs.myCroppa.chooseFile();
        },

        removePhoto(){
            this.isPhotoSelected = false
            this.$refs.myCroppa.refresh()
        },
        displayRefresh(){
            this.isPhotoSelected = true
        }
        
    },
    components:{
        "loader": () => import("@/components/Loaders/Button.vue"),
    }
}
</script>

<style scoped>
.news-croppa {
    display: flex;
    flex-direction: row;
}
.news-croppa-preview {
    margin-right: 20px;
}
.news-croppa-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.news-croppa-actions button {
    margin: 5px 0;
}
</style>